import React, { useEffect, useState } from 'react';
import './MatchVideoPc.css';
import VideoPc from '../VideoPc/VideoPc';
import MatchSchedulePc from '../matchtimePc/MatchSchedulePc';
import footballmp4 from '../../../assets/video/football.mp4'
import PartnerLiveAtTopPc from '../partnerlive/PartnerLiveAtTopPc';
const videoOriginal = [ { cdnlink :''} ,{ cdnlink :footballmp4}];
const dataSlide = 2;
const MatchVideoPc = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/rooms/list`);
                const result = await response.json();
                const filteredDataCdn = result.rooms.filter(match => match.cdnlink !== "1")
                .sort((a, b) => {
                    // Combine date and time into a single Date object for both a and b
                    const dateTimeA = new Date(`${a.date}T${a.time}`);
                    const dateTimeB = new Date(`${b.date}T${b.time}`);
                    
                    // Sort by the combined date and time
                    return dateTimeA - dateTimeB;
                });
                

                setData(filteredDataCdn);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);
    if (loading) {
        return <div>Loading...</div>;
    }
    if (data) {
        if (data.length > 1) {
            return (
                <div className='match_video_Pc d-flex justify-content-around align-items-start mt-5'>

                    {data && (
                        <>

                            <MatchSchedulePc data={data}></MatchSchedulePc>
                            <VideoPc data={data}></VideoPc>
                        </>
                    )}

                </div>



            );
        } else {
            return <div className='match_video_Pc d-flex justify-content-around align-items-start mt-5'>

                {data && (
                    <>
                       <div style={{width:'60%'}}>
                       <PartnerLiveAtTopPc dataSlide={dataSlide}></PartnerLiveAtTopPc>
                       </div>
                       <div className='mt-2'>
                       <VideoPc data={videoOriginal}></VideoPc>
                       </div>
                    </>
                )}
            </div>
        }
    }
};

export default MatchVideoPc;
