import React from 'react';
import './Community.css'
const Community = () => {
    return (
        <div className=' community text-center'>
            <h3>CHỨC NĂNG VẪN CÒN ĐANG PHÁT TRIỂN</h3>
        </div>
    );
}

export default Community;
