import React, { useState ,useEffect} from 'react';
import './HeaderPc.css';
import logo from '../../assets/image/mainlogo.webp';
import { NavLink } from 'react-router-dom'; 

const HeaderPc = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);  

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            setIsLoggedIn(true); 
        }
    }, []);

    return (
        <div className="header_Pc mt-3">
            <nav className="nav_bar_Pc mt-2 d-flex justify-content-between align-items-center">
                {/* Logo */}
                <div className='text-start'>
                    <img src={logo} width='50%' alt="500AE Logo" />
                </div>

                {/* Navigation Links */}
                <div>
                    <NavLink to="/" className="nav_link_Pc mx-3">TRANG CHỦ</NavLink>
                    <NavLink to="/soikeo" className="nav_link_Pc mx-3">TRẬN ĐẤU SẮP TỚI</NavLink>
                    <NavLink to="/bxh" className="nav_link_Pc mx-3">BẢNG XẾP HẠNG</NavLink>
                </div>

                {/* Login/Register or Avatar */}
                <div>
                    {isLoggedIn ? (
                        // Show avatar if user is logged in
                        <div>
                             <NavLink to="/profile" className="nav_link_Pc mx-3">TRANG CÁ NHÂN</NavLink>
                            
                        </div>
                    ) : (
                        // Show login/register if user is not logged in
                        <>
                            <NavLink to="/login" className="nav_link_Pc mx-3">ĐĂNG NHẬP</NavLink>
                            <NavLink to="/register" className="nav_link_Pc mx-3">ĐĂNG KÝ</NavLink>
                        </>
                    )}
                </div>
            </nav>
        </div>
    );
};

export default HeaderPc;
