import React from 'react';
import './Header.css';
import logo from '../../assets/image/mainlogo.webp';
import {  NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <header>
            <img src={logo} alt="500AE Logo" className="header_logo" />
            <nav className="nav_bar mt-2">
                <NavLink to="/" className="nav_link  mx-2">TRANG CHỦ</NavLink>
                <NavLink to="/community" className="nav_link mx-2">CỘNG ĐỒNG</NavLink>
                <NavLink to="/soikeo" className="nav_link mx-2">SOI KÈO</NavLink>
                <NavLink to="/bxh" className="nav_link mx-2">BXH</NavLink>
            </nav>
        </header>
    );
};

export default Header;
