import React from 'react';
import './Community.css';

const Community = () => {
    return (
        <div className='mt-5'>
            <div className="text-center main_text_community">
                <h1>CỘNG ĐỒNG </h1>
                <h1>ĐAM MÊ</h1>
                <p>Community Of Passion</p>
            </div>
            <div className="d-flex justify-content-center">
                <div className="community_one ">
                    <a href="https://t.me/TIP500AE" className='text-decoration-none text-light'>
                        <div className="community_box_one">
                            <div className="community_color">
                                <h1>KÈO</h1>
                                <h6>EURO TELE</h6>
                            </div>
                        </div>
                    </a>
                    <a href="https://www.facebook.com/500AESHBET/" className='text-decoration-none text-light'>
                        <div className="community_box_two text-center">
                            <h5>KÈO BÓNG</h5>
                            <h5>FACEBOOK</h5>
                        </div>
                    </a>
                </div>
                <div className="community_two">
                    <a href=" https://urlvn.net/dudoannhancd57k" className='text-decoration-none text-light'>
                        <div className="community_box_two text-center">
                            <h5>DỰ ĐOÁN </h5>
                            <h5>NHẬN QUÀ</h5>
                        </div>
                    </a>
                    <div className="community_box_one">
                        <div className="community_color">
                            <h6>KHUYẾN MÃI</h6>
                            <h1>HOT</h1>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Community;
