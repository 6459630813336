import React from 'react';
import footerPc from '../../assets/image/mainlogo.webp';
import './FooterPc.css'
const FooterPc = () => {
    return (
        <footer className="footer">
        <div className="container">
          <div className="footer-content">
            {/* Logo Section */}
            <div className="footer-logo">
              <img src={footerPc} alt="500AE Logo" />
              {/* <h2>500AE</h2> */}
              <p className="footer-tagline">Trực tiếp bóng đá - Tận hưởng đam mê</p>
            </div>
  
            {/* Quick Links Section */}
            <div className="footer-links">
              <h3>Liên kết nhanh</h3>
              <ul>
                <li><a href="/">Trang chủ</a></li>
                <li><a href="/soikeo">Lịch thi đấu</a></li>
                <li><a href="/bxh">BXH</a></li>
                <li><a href="/">Liên hệ</a></li>
                <li><a href="/">Về chúng tôi</a></li>
              </ul>
            </div>
  
            {/* Social Media Section */}
            <div className="footer-social">
              <h3>Kết nối với chúng tôi</h3>
              <div className="social-icons">
                <a href="https://facebook.com"><i className="fab fa-facebook"></i></a>
                <a href="https://twitter.com"><i className="fab fa-twitter"></i></a>
                <a href="https://youtube.com"><i className="fab fa-youtube"></i></a>
                <a href="https://instagram.com"><i className="fab fa-instagram"></i></a>
              </div>
            </div>
          </div>
  
          {/* Bottom Text */}
          <div className="footer-bottom">
            <p>© 2024 500AE. This Site Is Created By SHFC CRITIN</p>
          </div>
        </div>
      </footer>
    );
}

export default FooterPc;
