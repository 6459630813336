import React, { useState } from 'react';
import './MatchSchedule.css';
import { Link, useNavigate } from 'react-router-dom';
import arrow from '../../../assets/image/arrow.webp';
import dot from '../../../assets/image/dot.webp';

const MatchSchedule = ({ data }) => {
    const [visibleMatches, setVisibleMatches] = useState(2);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleSeeMore = () => {
        setVisibleMatches(filteredData.length);
        setIsExpanded(true);
    };

    const handleCollapse = () => {
        setVisibleMatches(2);
        setIsExpanded(false);
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const navigate = useNavigate();

    const handleViewNow = (roomId) => {
        navigate(`/rooms/${roomId}`);
    };
    const filteredData = data.filter(match => match.name !== "BLV Giang A Cay");
    return (
        <div className='matchSchedule'>
            {
                filteredData.slice(0, visibleMatches).map((match, index) => (
                    <Link to={`/rooms/${match._id}`} style={{textDecoration: 'none', color: 'white'}} key={index}>
                        <div className='match_schedule' key={index}>
                            <div className='match_name '>
                                <div className='match_team_one '>
                                    <p>{truncateText(match.homeTeam, 15)}</p>
                                    <img src={match.homeLogo} width='30px' height="30px" alt={`${match.homeTeam} logo`} />
                                </div>
                                <h2 className='mx-3 vs'>Vs</h2>
                                <div className='match_team_two'>
                                    <p>{truncateText(match.awayTeam, 15)}</p>
                                    <img src={match.awayLogo} width='30px' height="30px" alt={`${match.awayTeam} logo`} className='' />
                                </div>
                            </div>
                            <div className='match_time'>
                                <div className='match_time_schedule'>
                                    <p>{match.description}</p>
                                    <p>{match.name}</p>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <img src={dot} alt="" width='' />
                                        <h6 className='m-0 mx-1'>
                                            {match.time} - {match.date.substring(5)}
                                        </h6>
                                    </div>
                                </div>
                                <button onClick={() => handleViewNow(match._id)}>
                                    XEM NGAY
                                    <i className="fa-regular fa-circle-play ms-1"></i>
                                </button>
                            </div>
                        </div>
                    </Link>
                ))
            }
            <div className='match_see_more'>
                {!isExpanded && visibleMatches < filteredData.length && (
                    <div onClick={handleSeeMore}>
                        <a href="#/" className=''>XEM THÊM</a>
                        <img src={arrow} alt="" />
                    </div>
                )}
                {isExpanded && (
                    <div onClick={handleCollapse}>
                        <a href="#/" className=''>THU GỌN</a>
                        <i className="fa-solid fa-arrow-up"></i>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MatchSchedule;
