import React from 'react';
import './MatchPc.css'
import MatchVideoPc from '../matchvideoPc/MatchVideoPc';
const MatchPc = () => {
    return (
        <div className=' match_main_Pc'>
            <div className='container'>
                <div className='container'>
                    <div className=' d-flex match_text_main_Pc justify-content-between align-items-end'>
                    <div className='match_text_Pc '>
                        <h4 className=''>CẬP NHẬT </h4>
                        <h4> TRẬN HOT</h4>
                        <p>Upcoming Tournaments And Matches</p>
                        <p>GIẢI ĐẤU VÀ TRẬN ĐẤU SẮP TỚI</p>
                    </div>
                    <div>
                        <div>
                            <button className="btn_leauge_Pc active">EPL</button>
                            <button className="btn_leauge_Pc">Premier</button>
                            <button className="btn_leauge_Pc">LaLiGa</button>
                            <button className="btn_leauge_Pc">FA</button>
                            <button className="btn_leauge_Pc">Bundesliga</button>
                        </div>
                    </div>
                    </div>
                    <MatchVideoPc></MatchVideoPc>

                </div>
            </div>

        </div>
    );
}

export default MatchPc;
