import {React,useEffect,useState} from 'react';
import BannerPc from './Banner/BannerPc';
import MatchPc from './matchPc/MatchPc';
import CommunityPc from './communitypc/CommunityPc';
import StoryPc from './storyPc/StoryPc';
import PartnerLivePc from './partnerlive/PartnerLivePc'
const HomePagePc = () => {

    return (
        <div className='container'>
                <BannerPc></BannerPc>
                <MatchPc></MatchPc>
                <CommunityPc></CommunityPc>
                <StoryPc></StoryPc>
               <PartnerLivePc></PartnerLivePc>    
        </div>
    );
   }
export default HomePagePc;
