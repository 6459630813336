import React from 'react';
import './Match.css'
import MatchVideo from '../matchvideo/MatchVideo';
const Match = () => {
    return (
        <div className=' match_main'>
            <div className='container'>
            <div className=''>
                <div className='match_text '>
                    <h4 className=''>CẬP NHẬT </h4>
                    <h4> TRẬN HOT</h4>
                    <p>Upcoming Tournaments And Matches</p>
                    <span className="h8">GIẢI ĐẤU VÀ TRẬN ĐẤU SẮP TỚI</span>
                </div>
                <MatchVideo></MatchVideo>
              
            </div>
            </div>
            
        </div>
    );
}

export default Match;
