import React, { useEffect, useState } from 'react';
import './LiveStream.css';
import { useParams } from 'react-router-dom';
import VideoLive from '../home/Video/VideoLive';
import nav from '../../assets/image/nav.webp'
import bell from '../../assets/image/bell.webp'
import aepartner from '../../assets/image/2.png'
import shbetlogo from '../../assets/image/1.png'
import RelatedVideo from '../home/partnerlive/relatelive/RelatedLive';
const LiveStream = () => {
  const { roomId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [dataMatch, setDataMatch] = useState(null);
  const jwtToken = localStorage.getItem('accessToken');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/rooms/list`);
        const result = await response.json();
        setDataMatch(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/rooms/${roomId}`);

        if (!response.ok) {
          throw new Error('Failed to fetch room data');
        }

        const message = await response.json();

        if (message.message === "Truy Cập Room Thành Công, Có thể Emit để connect room socket") {
          setData(message.room.cdnlink);

        } else {
          throw new Error('Failed to connect to the live room');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRoomData();


  }, [roomId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className='live_main'>
      <div className=''>
        <div className='container  live_video pb-5'>
          <div className='d-flex justify-content-between align-items-center live_main_title' style={{ border: '1px solid #FF4500', backgroundColor: 'black', padding: '5px', borderRadius: '10px' }}>
            <div>
              {/* <img src={light} alt="" /> */}
            </div>
            <div className='text-center flex-grow-1' >
              <p className="m-0 aelive_text" >500AE TRỰC TIẾP BÓNG ĐÁ</p>
            </div>
            <div className='mx-3'>
              <img src={bell} alt="" />
            </div>
            <div className=''>
              <img src={nav} alt="" />
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-center main_video_live'>
            <div className='video_match_live' >
              {data && <VideoLive data={data} />}
            </div>
            <div className='iframe_video'>
              <iframe
                src={`${process.env.REACT_APP_API_URL}/${roomId}?token=${jwtToken}`}
                title="Live Stream"
                style={{ width: '100%', height: '500px' }}
              ></iframe>

            </div>
          </div>

        </div>
      </div>
      {dataMatch &&
        <div className=' mt-5 pb-5 d-flex live_match'>
          <div className='text-center container live_match_main' style={{ width: '68%',backgroundColor:'#071436',padding:'10px',borderRadius:'10px' }}>
            <h4>
              CÁC TRẬN ĐẤU KHÁC ĐANG TRỰC TIẾP
            </h4>
            <RelatedVideo data={dataMatch}></RelatedVideo>
          </div>
          <div className='text-center container live_bet_main' style={{ width: '30%' ,backgroundColor:'#071436',padding:'10px',borderRadius:'10px'}} >
            <h4>TOP NHÀ CÁI UY TÍN</h4>
            <div className='d-flex mt-3 justify-content-between align-items-center' style={{ backgroundColor: '#232E44', padding: '10px', borderRadius: '10px' }}>
              <div style={{ width: '25%' }}>
                <img src={shbetlogo} width='100%' height='100%' alt="" />
              </div>
              <div >
                <p className='m-0' style={{ fontSize: '0.7vw' }}>Nhà Cái Uy Tín Số 1 </p>
                <p style={{ fontSize: '0.7vw' }}>Innesta Làm Đại sứ </p>
              </div>
              <div>
              <a href="https://sh151.com/?f=3699955" className='button_betting text-decoration-none text-light'>CƯỢC NGAY</a>
              </div>
            </div>
            <div className='d-flex mt-3 justify-content-between align-items-center ' style={{ backgroundColor: '#232E44', padding: '10px', borderRadius: '10px' }}>
              <div style={{ width: '25%' }}>
                <img src={aepartner} width='100%' height='100%' alt="" />
              </div>
              <div >
                <p className='m-0' style={{ fontSize: '0.7vw' }}>Nhà Cái Uy Tín Số 1</p>
                <p style={{ fontSize: '0.7vw' }}>Đối Tác Của Nhiều CLB </p>
              </div>
              <div>
              <a href="https://sh151.com/?f=3699955" className='button_betting text-decoration-none text-light'>CƯỢC NGAY</a>
              </div>
            </div>
            
          </div>


        </div>}
    </div>
  );
};

export default LiveStream;
