import React from 'react';
import './StoryPc.css'
import largeLogo from '../../../assets/image/logoLarge.webp'
import aeImage from '../../../assets/image/500aeImage.webp'
const StoryPc = () => {
    return (
        <div className='background_story_Pc container'>
           <div className='container'>
           <div className='text-start mt-5 story_main_Pc mb-5 d-flex justify-content-between align-items-center'>
                <div className='story_text_main_Pc mt-2'>
                    <img src={largeLogo} width='50%' alt="" />
                    <h1 className='mt-2'>VỀ 500AE LIVE</h1>
                    <p className='story_main_text_Pc mt-2'>Câu chuyện về thương hiệu</p>
                    <h3>500AE Live là trang web hướng tới cộng đồng đam mê thể thao cung cấp hàng ngàn giải đấu xuyên suốt 24h</h3>
                    <button className='mt-5'>
                        <p className='mb-2  ms-2'>TÌM HIỂU THÊM
                            <i className="ms-4 fa-solid fa-arrow-right">
                            </i>
                        </p>
                    </button>
                </div>
                <div>
                    <img src={aeImage} width="100%" alt="" />
                </div>
            </div>
           </div>
        </div>
    );
}

export default StoryPc;
