import React, { useEffect, useState } from 'react';
import './MatchVideo.css';
import Video from '../Video/Video';
import MatchSchedule from '../matchtime/MatchSchedule';
import PartnerLive from '../partnerlive/PartnerLive';
import footballmp4 from '../../../assets/video/football.mp4'
const videoOriginal = [{ cdnlink: '' }, { cdnlink: footballmp4 }];
const MatchVideo = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/rooms/list`);
                const result = await response.json();
                const filteredDataCdn = result.rooms.filter(match => match.cdnlink !== "1").sort((a, b) => {
                    // Combine date and time into a single Date object for both a and b
                    const dateTimeA = new Date(`${a.date}T${a.time}`);
                    const dateTimeB = new Date(`${b.date}T${b.time}`);
                    
                    // Sort by the combined date and time
                    return dateTimeA - dateTimeB;
                });
                setData(filteredDataCdn);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }
    if (data.length > 1) {
        return (
            <div className='match_video'>
                <div>
                    <button className="btn_leauge active">EPL</button>
                    <button className="btn_leauge">Premier</button>
                    <button className="btn_leauge">LaLiGa</button>
                    <button className="btn_leauge">FA</button>
                    <button className="btn_leauge">Bundesliga</button>
                </div>
                {data && (
                    <>
                        <Video data={data}></Video>
                        <MatchSchedule data={data}></MatchSchedule>
                    </>
                )}
            </div>
        );
    } else {
        return <div className='match_video'>
            <div>
                <button className="btn_leauge active">EPL</button>
                <button className="btn_leauge">Premier</button>
                <button className="btn_leauge">LaLiGa</button>
                <button className="btn_leauge">FA</button>
                <button className="btn_leauge">Bundesliga</button>
            </div>
            {data && (
                <>
                    <Video data={videoOriginal}></Video>
                    <PartnerLive></PartnerLive>
                    {/* <MatchSchedule data={data}></MatchSchedule> */}
                </>
            )}
        </div>
    }

};

export default MatchVideo;
