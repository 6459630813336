import React, { useState } from 'react';
import './MatchSchedulePc.css';
import { Link, useNavigate } from 'react-router-dom';
import arrow from '../../../assets/image/arrow.webp';
import dot from '../../../assets/image/dot.webp';
import btnbongda from '../../../assets/image/bongdaitem.webp';
import calendar from '../../../assets/image/calendar.webp';
import ball from '../../../assets/image/ball.webp';
import field from '../../../assets/image/field.webp';
import location from '../../../assets/image/location.webp';

const MatchSchedulePc = ({ data }) => {
    const [visibleMatches, setVisibleMatches] = useState(2);
    const [isExpanded, setIsExpanded] = useState(false);

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const handleSeeMore = () => {
        setVisibleMatches(filteredData.length);
        setIsExpanded(true);
    };

    const handleCollapse = () => {
        setVisibleMatches(2);
        setIsExpanded(false);
    };

    const navigate = useNavigate();

    const handleViewNow = (roomId) => {
        navigate(`/rooms/${roomId}`);
    };


    
    const sortedData = data.sort((a, b) => (b.hot === "YES") - (a.hot === "YES"));
    const filteredData = sortedData.filter(match => match.name !== "BLV Giang A Cay" );
    console.log(filteredData)

    return (
        <div className='matchSchedule_Pc'>
            <div className='text-center video_livestream_Pc'>
                <img className='imgbongdabtn_Pc' src={btnbongda} width='100%' alt="" />
                <div className='video_stream_Pc'>
                    <div className='video_text_Pc container d-flex justify-content-around'>
                        <div className='d-flex justify-content-between align-items-center mb-2 video_text_icon_Pc'>
                            <div className='img_football_match_Pc mx-2'>
                                <img width='80%' src={calendar} alt="" />
                            </div>
                            <p className='m-0'>
                                Date
                            </p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mb-2 video_text_icon_Pc'>
                            <div className='img_football_match_Pc mx-2'>
                                <img width='80%' src={ball} alt="" />
                            </div>
                            <p className='m-0'>
                                Match
                            </p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mb-2 video_text_icon_Pc'>
                            <div className='img_football_match_Pc mx-2'>
                                <img width='80%' src={field} alt="" />
                            </div>
                            <p className='m-0'>
                                Ticket Price
                            </p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mb-2 video_text_icon_Pc'>
                            <div className='img_football_match_Pc mx-2'>
                                <img width='80%' src={location} alt="" />
                            </div>
                            <p className='m-0'>
                                Venue
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {
                filteredData.slice(0, visibleMatches).map((match, index) => (
                    <Link to={`/rooms/${match._id}`} style={{ textDecoration: 'none', color: 'white' }} key={index}>
                        <div className='match_schedule_Pc d-flex align-items-center justify-content-around'>
                            <div className='match_time_schedule_Pc'>
                                <p>{match.description}</p>
                                <p>{match.name}</p>
                                <h6>
                                    <img src={dot} alt="" />
                                    {match.time} - {match.date.substring(5)}
                                </h6>
                            </div>
                            <div className='match_name_Pc'>
                                <div className='match_team_one_Pc'>
                                    <p>{truncateText(match.homeTeam, 10)}</p>
                                    <div>
                                        <img src={match.homeLogo} width='40px' height='40px' alt={`${match.homeTeam} logo`} />
                                    </div>
                                </div>
                                <h2 className='mx-3'>Vs</h2>
                                <div className='match_team_two_Pc'>
                                    <p>{truncateText(match.awayTeam, 10)}</p>
                                    <div>
                                        <img src={match.awayLogo} width='40px' height='40px' alt={`${match.awayTeam} logo`} className='' />
                                    </div>
                                </div>
                            </div>

                            <div className='match_time_Pc'>
                                <button onClick={() => handleViewNow(match._id)}>
                                    XEM NGAY
                                    <i className="fa-regular fa-circle-play ms-1"></i>
                                </button>
                            </div>
                        </div>
                    </Link>
                ))
            }
            <div className='match_see_more_Pc'>
                {!isExpanded && visibleMatches < filteredData.length && (
                    <div onClick={handleSeeMore}>
                        <a href="#/" className=''>XEM THÊM</a>
                        <img src={arrow} alt="" />
                    </div>
                )}
                {isExpanded && (
                    <div onClick={handleCollapse}>
                        <a href="#/" className=''>THU GỌN</a>
                        <i className="fa-solid fa-arrow-up"></i>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MatchSchedulePc;
