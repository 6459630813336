import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import footballvideo from '../../../../assets/video/football.mp4';
import aepartner from '../../../../assets/image/2.png'
import shbetlogo from '../../../../assets/image/1.png'
import useStore from '../../../store/useStore';
import './VideoPartner.css';
import PartnerLivePc from '../../../hompagePc/partnerlive/PartnerLivePc';
import PartnerLive from '../PartnerLive';
const jwtToken = localStorage.getItem('accessToken');
const VideoPartnerLive = () => {
    const videoList = useStore((state) => state.videoList);
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const path = window.location.pathname;
    const matchId = path.split('/').pop();

    // Filter video from the store based on matchId
    const filteredVideo = videoList.length ? videoList.filter(video => video.matchId === matchId) : [];
    const m3u8Url = filteredVideo.length ? filteredVideo[0].m3u8 : null;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Handle video interaction (e.g., play on click)
    const handleUserInteraction = () => {
        if (!isPlaying && videoRef.current) {
            videoRef.current.muted = false; // Unmute the video
            videoRef.current.play(); // Start playing
            setIsPlaying(true); // Update the playing state
        }
    };

    useEffect(() => {
        const video = videoRef.current;
        if (m3u8Url && video) {
            if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(m3u8Url);
                hls.attachMedia(video);

                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    video.muted = true; // Mute the video for autoplay
                    video.play().catch(() => {
                        ;
                    });
                });

                return () => hls.destroy(); // Cleanup on unmount
            } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.src = m3u8Url;
                video.muted = true;
                video.play().catch(() => {

                });
            } else {
                video.src = footballvideo; // Fallback to MP4 video
                video.muted = true;
                video.play().catch(() => {

                });
            }
        }
    }, [m3u8Url]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div style={{ backgroundColor: '#020818' }}>
            <div className='videopartner text-center'>
                <div className='pt-5'>
                    <div className='mt-1 py-3' style={{ border: '1px solid #FF4500', backgroundColor: 'black', padding: '5px', borderRadius: '10px' }}>
                        <h3 className='mb-0' style={{ color: '#fb1515' }} >500AE TRỰC TIẾP BÓNG ĐÁ</h3>
                    </div>
                </div>

                <div className='videopartner1 d-flex justify-content-between align-items-center py-5 px-3'>
                    {/* Main Live Video */}
                    <div className='video_match_live'>
                        <video
                            ref={videoRef}
                            controls
                            width="100%"
                            height="100%"
                            onClick={handleUserInteraction}
                        >
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    {/* Additional iframe content */}
                    <div className='mx-1'>
                        <iframe
                            className='iframe_video'
                            // src={`${process.env.REACT_APP_API_URL}/66e2d08c51acae5963c0cdd9`}
                            src={`${process.env.REACT_APP_API_URL}/66e2d08c51acae5963c0cdd9?token=${jwtToken}`}
                            title="Live Stream"
                            style={{ width: '100%', height: '500px' }}
                        ></iframe>
                    </div>
                </div>
            </div>

            {/* Related Partner Videos */}
            <div className='mt-5 partnerlive_relate container'>
                <div className='container'>
                    {isMobile ? <PartnerLive /> : <PartnerLivePc />}
                </div>
                <div className='text-center container live_bet_main' style={{ width: '30%', backgroundColor: '#071436', padding: '10px', borderRadius: '10px' }} >
                    <h4>TOP NHÀ CÁI UY TÍN</h4>
                    <div className='d-flex mt-3 justify-content-between align-items-center' style={{ backgroundColor: '#232E44', padding: '10px', borderRadius: '10px' }}>
                        <div style={{ width: '25%' }}>
                            <img src={shbetlogo} width='100%' height='100%' alt="" />
                        </div>
                        <div >
                            <p className='m-0' style={{ fontSize: '0.7vw' }}>Nhà Cái Uy Tín Số 1 </p>
                            <p style={{ fontSize: '0.7vw' }}>Innesta Làm Đại sứ </p>
                        </div>
                        <div>
                            <a href="https://sh151.com/?f=3699955" className='button_betting text-decoration-none text-light'>CƯỢC NGAY</a>
                        </div>
                    </div>
                    <div className='d-flex mt-3 justify-content-between align-items-center ' style={{ backgroundColor: '#232E44', padding: '10px', borderRadius: '10px' }}>
                        <div style={{ width: '25%' }}>
                            <img src={aepartner} width='100%' height='100%' alt="" />
                        </div>
                        <div >
                            <p className='m-0' style={{ fontSize: '0.7vw' }}>Nhà Cái Uy Tín Số 1</p>
                            <p style={{ fontSize: '0.7vw' }}>Đối Tác Của Nhiều CLB </p>
                        </div>
                        <div>
                        <a href="https://sh151.com/?f=3699955" className='button_betting text-decoration-none text-light'>CƯỢC NGAY</a>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default VideoPartnerLive;
