import React from 'react';
import './BannerPc.css'
import text500ae from '../../../assets/image/text500ae.webp';

const BannerPc = () => {
    return (
       <div className='mt-5'>
             <div className='banner_Pc'>
            <img src={text500ae} alt="" />

            <div className='banner_button_PC mt-4'>
                <button>
                    <p className='mb-2  ms-2'>JOIN OUR CLUB
                        <i className="ms-4 fa-solid fa-arrow-right">              
                        </i>
                    </p>
                </button>
            </div>
        </div>
       </div>
    );
}

export default BannerPc;
