import React from 'react';
import { NavLink } from 'react-router-dom'
import './ToolBar.css'
import taikhoan from '../../assets/image/taikhoan.webp'
import cuocngay from '../../assets/image/cuocngay.webp'
import tintuc from '../../assets/image/tintuc.webp'
import vongquay from '../../assets/image/vongquay.webp'
import live from '../../assets/image/live.webp'
const ToolBar = () => {
    return (
        <div>
            <div className="toolbar">
                <NavLink to="/profile" className="toolbar-item">
                    {/* <i class="fa-regular fa-user"></i> */}
                    <img src={taikhoan} alt="" />
                    <span>Tài Khoản</span>
                </NavLink>
                <NavLink to="/news" className="toolbar-item">
                    {/* <i class="fa-regular fa-newspaper"></i> */}
                    <img src={tintuc} alt="" />
                    <span>Tin tức</span>
                </NavLink>
                <NavLink to="/betting" className="toolbar-item">
                    {/* <i class="fa-brands fa-bitcoin"></i> */}
                    <img src={cuocngay} alt="" />

                    <span>Cược ngay</span>
                    <div className="underline" />
                </NavLink>
                <NavLink to="/listRoom" className="toolbar-item">
                    {/* <i class="fa-solid fa-camera"></i> */}
                    <img src={live} alt="" />

                    <span>Live</span>
                </NavLink>
                <NavLink to="/wheel" className="toolbar-item">
                    {/* <i class="fa-solid fa-circle-notch"></i> */}
                    <img src={vongquay} alt="" />
                    <span>Vòng quay</span>
                </NavLink>
            </div>

        </div>
    );
}

export default ToolBar;
